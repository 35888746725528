<template>
  <b-modal v-model="modal" size="xl" hide-footer centered>
    <template #modal-title>
      {{ client.name }}
    </template>
    <div>
      <b-table striped hover :items="games" :fields="makeFields()">
        <template #cell(actions)="data" v-if="isItForAdmin">
          <b-dropdown variant="link" no-caret>
            <template #button-content>
              <i class="fa fa-fw fa-bars font-size-16" />
            </template>
            <b-dropdown-item @click="remove(data.item)">
              <i class="uil-trash m-2 font-size-18" />
              <span class="align-middle ml-5">ELIMINAR</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ADMIN_ROLE } from '../../../../../helpers/CONSTANTS';

export default {
  props: {
    games: {
      type: Array,
      default: () => {
        return [];
      },
    },
    client: Object,
    role: String,
  },
  data() {
    return {
      modal: false,
      fields: [],
    };
  },
  computed: {
    ...mapGetters({
      success: 'clients/isSuccess',
      isItForAdmin: 'user/onlyAdminRole',
    }),
  },
  methods: {
    makeFields() {
      const globalFields = [
        {
          key: 'name',
          label: 'RUEDA',
          class: 'text-center',
        },
      ];
      const adminFields = [
        {
          key: 'actions',
          sortable: false,
          label: this.$t('actions.text'),
          class: 'align-middle  text-center',
        },
      ];
      if (this.role === ADMIN_ROLE) {
        return [...globalFields, ...adminFields];
      }

      return globalFields;
    },
    closeModa() {
      this.modal = false;
      this.$emit('closeModal');
    },
    async remove(wheel) {
      const { isConfirmed } = await this.$swal.fire({
        title: 'Estas seguro de eliminar el juego?',
        icon: 'question',
        showDenyButton: true,
      });

      if (!isConfirmed) return;

      const { uuid: gameUuid } = wheel;
      // /:uuid/game/:gameUuid
      await this.removeWheel({ gameUuid, uuid: this.client.uuid });

      if (!this.success)
        return this.$swal.fire(
          'Error removiendo el juego del cliente',
          '',
          'info'
        );

      this.$swal.fire('Juego removido', '', 'success');
      this.closeModa();
    },
    ...mapActions({
      removeWheel: 'clients/removeWheel',
    }),
  },
};
</script>
