<template>
  <b-modal id="bv-modal-example" v-model="modal" size="lg" hide-footer>
    <template #modal-title>
      {{ $t('helpers.add') }} {{ $t('filter.roulette') }}
    </template>
    <div>
      <form @submit.prevent="save()">
        <b-row>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label> {{ $t('filter.roulette') }} </label>
              <div>
                <!--  <select
                  v-model="typeform.roulette"
                  name="roulette"
                  class="form-select"
                  :class="{
                    'is-invalid': typesubmit && $v.typeform.roulette.$error,
                  }"
                  placeholder="Seleccione.."
                  @change="onChangeRoulette($event)"
                > -->
                <select
                  name="roulette"
                  class="form-select border-0 shadow-lg"
                  placeholder="Seleccione.."
                  @change="onChangeRoulette($event)"
                >
                  <option disabled selected>
                    {{ $t('helpers.select') }}...
                  </option>
                  <option
                    v-for="(item, index) in roulettes"
                    :key="index"
                    :value="item._id"
                  >
                    {{ item.name }}
                  </option>
                </select>
                <div
                  v-if="typesubmit && $v.typeform.roulette.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.typeform.roulette.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>{{ $t('roulettes.form.pleno') }}</label>
              <vs-input
                v-model="typeform.pleno"
                border
                class="shadow-lg"
                :class="{
                  'is-invalid': typesubmit && $v.typeform.pleno.$error,
                }"
              />
              <div
                v-if="typesubmit && $v.typeform.pleno.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.typeform.pleno.required">
                  Este campo es requerido.
                </span>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>{{ $t('roulettes.form.semipleno') }}</label>
              <vs-input
                v-model="typeform.semipleno"
                border
                class="shadow-lg"
                :class="{
                  'is-invalid': typesubmit && $v.typeform.semipleno.$error,
                }"
              />
              <div
                v-if="typesubmit && $v.typeform.semipleno.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.typeform.semipleno.required">
                  Este campo es requerido.
                </span>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>{{ $t('roulettes.form.calle3') }}</label>
              <div>
                <vs-input
                  v-model="typeform.calle"
                  border
                  class="shadow-lg"
                  :class="{
                    'is-invalid': typesubmit && $v.typeform.calle.$error,
                  }"
                />
                <div
                  v-if="typesubmit && $v.typeform.calle.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.typeform.calle.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>{{ $t('roulettes.form.calle4') }}</label>
              <div>
                <vs-input
                  v-model="typeform.cuadro"
                  border
                  class="shadow-lg"
                  :class="{
                    'is-invalid': typesubmit && $v.typeform.cuadro.$error,
                  }"
                />
                <div
                  v-if="typesubmit && $v.typeform.cuadro.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.typeform.cuadro.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>{{ $t('roulettes.form.calle6') }}</label>
              <div>
                <vs-input
                  v-model="typeform.linea"
                  border
                  class="shadow-lg"
                  :class="{
                    'is-invalid': typesubmit && $v.typeform.linea.$error,
                  }"
                />
                <div
                  v-if="typesubmit && $v.typeform.linea.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.typeform.linea.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>{{ $t('roulettes.form.column') }}</label>
              <div>
                <vs-input
                  v-model="typeform.columna"
                  border
                  class="shadow-lg"
                  :class="{
                    'is-invalid': typesubmit && $v.typeform.columna.$error,
                  }"
                />
                <div
                  v-if="typesubmit && $v.typeform.columna.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.typeform.columna.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>{{ $t('roulettes.form.dozen') }}</label>
              <div>
                <vs-input
                  v-model="typeform.docena"
                  border
                  class="shadow-lg"
                  :class="{
                    'is-invalid': typesubmit && $v.typeform.docena.$error,
                  }"
                />
                <div
                  v-if="typesubmit && $v.typeform.docena.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.typeform.docena.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>{{ $t('roulettes.form.chanceSimple') }}</label>
              <div>
                <vs-input
                  v-model="typeform.chanceSimple"
                  border
                  class="shadow-lg"
                  :class="{
                    'is-invalid': typesubmit && $v.typeform.chanceSimple.$error,
                  }"
                />
                <div
                  v-if="typesubmit && $v.typeform.chanceSimple.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.typeform.chanceSimple.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>{{ $t('roulettes.form.cubre') }}</label>
              <div>
                <vs-input
                  v-model="typeform.cubre"
                  border
                  class="shadow-lg"
                  :class="{
                    'is-invalid': typesubmit && $v.typeform.cubre.$error,
                  }"
                />
                <div
                  v-if="typesubmit && $v.typeform.cubre.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.typeform.cubre.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <!-- <b-row>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Duración de ronda</label>
              <div>
                <vs-input v-model="typeform.roundDuration" border class="shadow-lg" :class="{
                  'is-invalid':
                    typesubmit && $v.typeform.roundDuration.$error,
                }" />
                <div v-if="typesubmit && $v.typeform.roundDuration.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.roundDuration.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
        </b-row> -->

        <div class="mb-3 mb-0">
          <div class="d-flex">
            <vs-button>{{ $t('form.save') }}</vs-button>
            <vs-button @click="closeModa()" success type="button">
              {{ $t('form.abort') }}
            </vs-button>
          </div>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import Swal from 'sweetalert2';

export default {
  props: {
    client: {
      type: Object,
      default: () => {
        return {};
      },
    },
    roulettes: {
      type: Array,
    },
  },
  data() {
    return {
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      modal: false,
      typeform: {},
      fichas: [
        { valor: 0.1 },
        { valor: 0.5 },
        { valor: 1 },
        { valor: 5 },
        { valor: 10 },
      ],
      rouletteSelected: {},
    };
  },
  validations: {
    typeform: {
      roulette: {},
      pleno: {
        required,
      },
      semipleno: {
        required,
      },
      calle: {
        required,
      },
      cuadro: {
        required,
      },
      linea: {
        required,
      },
      columna: {
        required,
      },
      docena: {
        required,
      },
      chanceSimple: {
        required,
      },
      cubre: {
        required,
      },
    },
  },
  methods: {
    async save() {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (!this.typeform._id) {
        //Select a rulette
        return;
      }
      if (this.$v.$invalid) return console.log('error saving roulette');

      try {
        const { data } = await this.$http.put(
          `clients/${this.client._id}/assign-roulettes`,
          this.typeform
        );

        if (!data.ok) {
          return Swal.fire({
            position: 'center',
            icon: 'error',
            title: data.msg,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Ruleta agregada con exito',
          showConfirmButton: false,
          timer: 1500,
        });
        this.closeModa();
      } catch (error) {
        console.log('ERROR SAVING ROULETTES', error);
      }
    },
    defaultValue(value) {
      let roundDuration =
        value.target.options[value.target.options.selectedIndex].getAttribute(
          'data-roundDuration'
        );

      this.typeform.roundDuration = roundDuration;
    },
    closeModa() {
      this.typeform = {};
      this.modal = false;
    },
    onChangeRoulette(event) {
      const id = event.target.value;
      const rouletteFiltered = this.roulettes.find(
        (roulette) => roulette._id === id
      );
      this.typeform = rouletteFiltered;
    },
  },
};
</script>
