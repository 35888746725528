<template>
  <b-modal id="bv-modal-example" v-model="modal" size="lg" hide-footer>
    <template #modal-title>
      {{ $t('actions.subItems.import') }} {{ $t('filter.roulette') }}
    </template>
    <div>
      <form @submit.prevent="save">
        <b-row class="justify-content-center">
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>{{ $t('sidebar.clients') }}</label>
              <div>
                <select
                  v-model="typeform.from"
                  name="from"
                  class="form-select border-0 shadow-lg"
                  :class="{
                    'is-invalid': typesubmit && $v.typeform.from.$error,
                  }"
                  placeholder="Seleccione.."
                >
                  <option disabled selected>
                    {{ $t('helpers.select') }}...
                  </option>
                  <option
                    v-for="(item, index) in clients"
                    :key="index"
                    :value="item._id"
                  >
                    {{ item.name }}
                  </option>
                </select>
                <div
                  v-if="typesubmit && $v.typeform.from.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.typeform.from.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="mb-3 mb-0">
          <div class="d-flex">
            <vs-button>{{ $t('form.save') }}</vs-button>
            <vs-button @click="closeModa()" success type="button">
              {{ $t('form.abort') }}
            </vs-button>
          </div>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    client: {
      type: Object,
      default: () => {
        return {};
      },
    },
    clients: {
      typeof: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      modal: false,
      typeform: {},
    };
  },
  validations: {
    typeform: {
      from: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      isSuccess: 'clients/isSuccess',
      message: 'clients/getMessage',
    }),
  },
  methods: {
    async save() {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) return console.log('error', this.typeform);

      try {
        await this.importRoulette({
          id: this.client._id,
          from: this.typeform.from,
        });

        if (!this.isSuccess) {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: this.message,
            showConfirmButton: false,
            timer: 1500,
          });
          return this.closeModa();
        }
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: this.message,
          showConfirmButton: false,
          timer: 1500,
        });
        this.closeModa();
      } catch (error) {
        console.log('ERROR SAVE CLIENT', error);
      }
    },
    closeModa() {
      this.typeform = {};
      this.modal = false;
    },
    ...mapActions({
      importRoulette: 'clients/importRoulette',
    }),
  },
};
</script>
