<template>
  <Layout>
    <PageHeader
      :title="title"
      moda_title="Datos del cliente"
      ref="pageForm"
      :items="items"
      :isAdmin="isItForAdmin"
    >
      <template>
        <PageForm
          :typeform="typeform"
          @closeModa="closeModa"
          @getData="getData()"
        />
      </template>
    </PageHeader>
    <AddRoulette
      :roulettes="roulettesFiltered"
      :client="clientSave"
      ref="AddRoulette"
    />
    <ListRoulette
      :item="clientRoulette"
      :roulettes="rouletteProps"
      :client="clientData"
      :userRole="userRole"
      ref="ListRoulette"
    />
    <ImportRoulette
      :client="clientSave"
      :clients="clientsAgaint"
      ref="ImportRoulette"
    />
    <ViewUrls :item="urlCliente" ref="viewUrls" />
    <AddCurrency
      :model="clientData"
      :currencies="currencies"
      ref="addCurrency"
      path="clients"
    ></AddCurrency>
    <GameModalMenu
      ref="GameModalMenu"
      :model="clientData"
      :isAdding="isAdding"
      @openListGame="handleListGame"
    >
    </GameModalMenu>
    <AddGame
      ref="addGame"
      :model="clientData"
      :games="clientGames"
      :path="path"
      :endpoint="endpoint"
      @closeGameMenu="closeGameMenu"
    ></AddGame>
    <AddDragonTiger
      ref="addDragonTiger"
      :model="clientData"
      :games="clientGames"
      :path="path"
    />
    <AddWheel ref="addWheel" :client="clientData" :wheels="clientGames" />
    <ListGame
      :games="clientGames"
      :client="clientData"
      ref="ListExternalGames"
    />
    <ListDragonTiger
      :games="clientGames"
      :client="clientData"
      ref="ListDragonTiger"
    />
    <ListWheels
      :games="clientGames"
      :client="clientData"
      :role="userRole"
      ref="ListWheelFortune"
    />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <Tooltip>
                <ol>
                  <li>
                    Agregar:
                    <ul>
                      <li>Datos del cliente</li>
                      <li>
                        Datos para inicio de sesion del cliente. Colocar su rol
                        correspondiente. Podrás editar estos datos en el modulo
                        de usuarios.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Detalle del cliente: Allí encontrarás el id y token de
                    validación correspondiente.
                  </li>
                  <li>
                    Configuración de ruleta: Desde 0, o importar la
                    configuracion de ruletas de otros clientes.
                  </li>
                  <li>
                    Bloqueo de cliente: Esto quita la posibilidad de que sus
                    jugadores puedan acceder a las salas.
                  </li>
                </ol>
                <span
                  >Nota: Luego de agregar el cliente, necesitará un
                  operador.</span
                >
              </Tooltip>
            </div>
            <div class="row my-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.show') }}&nbsp;
                    <vs-select v-model="perPage" class="shadow-lg">
                      <vs-option
                        v-for="(op, index) in pageOptions"
                        :key="index"
                        :label="op"
                        :value="op"
                        >{{ op }}</vs-option
                      >
                    </vs-select>
                    {{ $t('filter.entries') }}
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.search') }}:
                    <vs-input
                      primary
                      v-model="filter"
                      type="search"
                      placeholder="Buscar..."
                      class="shadow-lg"
                    />
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <b-overlay :show="loading" rounded="lg">
              <div class="table-responsive mb-0">
                <b-table
                  :items="tableData"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                >
                  <template #cell(logo)="data">
                    <b-img
                      :src="data.item.logo"
                      height="80px"
                      width="auto"
                      class="logo-img"
                      @error="handleError"
                    ></b-img>
                  </template>
                  <template #cell(urls)="data">
                    <div class="d-flex justify-content-center">
                      <!-- <b-button
                        variant="outline-primary"
                        @click="openUrls(data.item)"
                        >Urls</b-button
                      > -->
                      <vs-button @click="openUrls(data.item)">Urls</vs-button>
                    </div>
                  </template>
                  <template #cell(games)="data">
                    <div class="d-flex justify-content-center">
                      <!-- <b-button
                        variant="outline-primary"
                        @click="openRulettes(data.item)"
                        >Ruletas</b-button
                      > -->
                      <vs-button @click="handleOpen(data.item)">
                        {{ $t('sidebar.games.text').toUpperCase() }}
                      </vs-button>
                    </div>
                  </template>
                  <!-- Column: Actions -->
                  <template #cell(actions)="data" class="">
                    <div class="action-btn-container action-table">
                      <b-dropdown
                        variant="link"
                        no-caret
                        class="action-btn position-absolute dropdown-items"
                      >
                        <template #button-content>
                          <i class="fa fa-fw fa-bars font-size-16" />
                        </template>
                        <div>
                          <b-dropdown-item
                            :to="{
                              name: 'client-show',
                              params: { id: data.item._id },
                            }"
                          >
                            <i class="uil-file-alt m-2 font-size-18" />
                            <span class="align-middle ml-5">{{
                              $t('actions.subItems.details')
                            }}</span>
                          </b-dropdown-item>
                          <div v-if="isItForAdmin">
                            <b-dropdown-item @click="edit(data.item)">
                              <i class="uil-edit m-2 font-size-18" />
                              <span class="align-middle ml-5">{{
                                $t('actions.subItems.edit')
                              }}</span>
                            </b-dropdown-item>
                          </div>
                          <div v-if="isItForAdmin">
                            <b-dropdown-item @click="addCurrency(data.item)">
                              <i class="uil-coins m-2 font-size-18" />
                              <span class="align-middle ml-5"
                                >{{ $t('helpers.add') }}
                                {{ $t('filter.currency') }}</span
                              >
                            </b-dropdown-item>
                          </div>
                          <div v-if="isItForAdmin">
                            <b-dropdown-item @click="addGame(data.item)">
                              <i
                                class="uil-create-dashboard m-2 font-size-18"
                              />
                              <span class="align-middle ml-5"
                                >{{ $t('helpers.add') }}
                                {{ $t('sidebar.games.text') }}</span
                              >
                            </b-dropdown-item>
                          </div>
                          <div v-if="isItForAdmin">
                            <b-dropdown-item @click="ImportRoulette(data.item)">
                              <i
                                class="uil-right-indent-alt m-2 font-size-18"
                              />
                              <span class="align-middle ml-5"
                                >{{ $t('actions.subItems.import') }}
                                {{ $t('filter.roulette') }}</span
                              >
                            </b-dropdown-item>
                          </div>
                          <div v-if="isItForAdmin">
                            <b-dropdown-item @click="blockClient(data.item)">
                              <i class="uil-chart-down m-2 font-size-18" />
                              <span class="align-middle ml-5">
                                {{
                                  data.item.available
                                    ? $t('actions.subItems.block')
                                    : $t('actions.subItems.disblock')
                                }}
                                {{ $t('filter.client') }}</span
                              >
                            </b-dropdown-item>
                          </div>
                          <div v-if="isItForAdmin">
                            <b-dropdown-item @click="remove(data.item)">
                              <i
                                class="mdi mdi-delete-outline m-2 font-size-18"
                              />
                              <span
                                class="align-middle ml-5"
                                data-action="Eliminar"
                                >{{ $t('actions.subItems.delete') }}</span
                              >
                            </b-dropdown-item>
                          </div>
                        </div>
                      </b-dropdown>
                    </div>
                  </template>
                </b-table>
              </div>
            </b-overlay>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Swal from 'sweetalert2';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Layout from '../../layouts/main';
import appConfig from '@/app.config';
import {
  Tooltip,
  PageHeader,
  AddGame,
  AddDragonTiger,
  ListGame,
  ListDragonTiger,
  GameModalMenu,
  AddCurrency,
} from '../../../components';
import {
  AddWheel,
  AddRoulette,
  ImportRoulette,
  ListRoulette,
  ListWheels,
  PageForm,
  ViewUrls,
} from './components';
import { breadCrumbData } from '../../../helpers/breadcrumbs/breadCrumbs';
import { EXTERNAL_GAMES } from '@/helpers/CONSTANTS';
import { clientFields } from '@/helpers/fields';

export default {
  page: {
    title: 'Clientes',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    AddCurrency,
    AddDragonTiger,
    AddGame,
    AddRoulette,
    AddWheel,
    ImportRoulette,
    Layout,
    ListRoulette,
    ListGame,
    ListDragonTiger,
    ListWheels,
    GameModalMenu,
    PageHeader,
    PageForm,
    Tooltip,
    ViewUrls,
  },
  data() {
    return {
      clientData: {},
      currencies: [],
      rouletteProps: [],
      tableData: [],
      clientsAgaint: [],
      title: this.$t('sidebar.clients'),
      items: breadCrumbData.clients,
      roulettes: [],
      roulettesFiltered: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: 'age',
      sortDesc: false,
      fields: clientFields,
      typeform: {
        name: '',
        logo: '',
        endpointWin: '',
        endpointRollback: '',
        endpointBet: '',
        endpointAuth: '',
        _id: '',
      },
      clientSave: {},
      urlCliente: {},
      clientRoulette: [],
      loading: false,
      userRole: '',
      isAdding: false,
      clientGames: [],
      path: '',
      endpoint: '',
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    ...mapGetters({
      user: 'user/getUserData',
      getCurrencies: 'currencies/getCurrencies',
      getClients: 'clients/getClients',
      isSuccess: 'clients/isSuccess',
      getMessage: 'clients/getMessage',
      getClientRoulettes: 'clients/getClientRoulettes',
      roulettesToAdd: 'clients/getRoulettes',
      getGames: 'games/getGames',
      getClientExternalGames: 'clients/getGames',
      getClientDragonTigers: 'clients/getDragonTigers',
      getDragonTigers: 'dragonTiger/getGames',
      getWheels: 'wheel/getWheels',
      getClientWheels: 'clients/getWheels',
      envs: 'notification/mapEnvs',
      getErrors: 'notification/getErrors',
      isItForAdmin: 'user/onlyAdminRole',
    }),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getData();
    this.getUserRole();
    this.getRoulettes();
  },
  methods: {
    closeGameMenu() {
      this.$refs.GameModalMenu.modal = false;
    },
    addGame(item) {
      this.clientData = item;
      this.isAdding = true;
      this.$refs.GameModalMenu.modal = true;
    },
    handleAddGame(game, model) {
      switch (game.name) {
        case 'Roulette':
          this.addRoulette(model);
          break;
        case 'Dragon Tiger':
          this.addDragonTiger(model);
          break;
        case 'Wheel Fortune':
          this.addWheelFortune(model);
          break;
        case 'External Games':
          this.addExternalGame(model);
          break;
        default:
          break;
      }
    },
    async handleListRoulette(model) {
      await this.fetchClientRoulettes({ id: model._id });
      const roulettes = this.getClientRoulettes.map((cr) => {
        return {
          ...cr.roulette,
          clientRoulette: cr._id,
        };
      });
      this.clientRoulette = roulettes;
      this.rouletteProps = roulettes;
      this.clientData = model;
      this.loading = false;
    },
    async handleListDragonTiger(model) {
      const { uuid } = model;
      await this.fetchClientDragonTigers({ uuid });

      this.clientGames = this.getClientDragonTigers;

      this.clientData = model;
      this.loading = false;
    },
    async handleListWheels(model) {
      const { uuid } = model;
      await this.fetchClientWheels({ uuid });

      this.clientGames = this.getClientWheels;
      this.clientData = model;
      this.loading = false;
    },
    async handleListGames(model) {
      await this.fetchClientExternalGames({ id: model._id });

      const games = this.getClientExternalGames
        .filter((cr) => cr.externalGame)
        .map((cr) => cr.externalGame);
      this.clientGames = games;
      this.clientData = model;
      this.loading = false;
    },
    async handleListGame(data) {
      const { game, model } = data;

      if (this.isAdding) return this.handleAddGame(game, model);

      this.loading = true;
      switch (game.name) {
        case 'Roulette': {
          await this.handleListRoulette(model);
          break;
        }
        case 'Dragon Tiger':
          {
            await this.handleListDragonTiger(model);
          }
          break;
        case 'External Games':
          {
            await this.handleListGames(model);
          }
          break;
        case 'Wheel Fortune':
          {
            await this.handleListWheels(model);
          }
          break;

        default:
          break;
      }

      if (!this.$refs[game.ref]) return (this.loading = false);
      this.$refs[game.ref].modal = true;
    },
    async getRoulettes() {
      try {
        const { data } = await this.$http.get('/roulettes');
        this.clientRoulettes = data.roulettes;
      } catch (error) {
        console.log(error);
      }
    },
    async addRoulette(item) {
      this.clientSave = item;

      await this.fetchClientRoulettes({ id: item._id });
      const clientRoulettes = this.getClientRoulettes.map((cr) => cr.roulette);
      await this.filterRoulette({
        clientRoulettes,
        roulettes: this.clientRoulettes,
      });
      this.roulettesFiltered = this.roulettesToAdd;

      this.$refs.AddRoulette.modal = true;
    },
    async getData() {
      try {
        await this.fetchClients();
        this.tableData = this.getClients;
      } catch (error) {
        console.log('ERROR GETTING DATA', error);
      }
    },
    async addDragonTiger(model) {
      await this.fetchDragonTigers();
      await this.fetchClientDragonTigers({ uuid: model.uuid });

      const gamesIds = this.getDragonTigers.map((game) => game.uuid);
      const clientGamesIds = this.getClientDragonTigers.map(
        (dragonTiger) => dragonTiger.uuid
      );

      const difference = gamesIds.filter(
        (x) => clientGamesIds.indexOf(x) === -1
      );
      const roulettesToAdd = this.getDragonTigers.filter((game) =>
        difference.includes(game.uuid)
      );

      this.path = `/client/${model.uuid}/add-game`;
      this.clientGames = roulettesToAdd;
      this.clientData = model;

      this.$refs.addDragonTiger.modal = true;
    },
    async addWheelFortune(model) {
      const { uuid } = model;

      this.clientData = model;
      this.clientGames = await this.getWheelsToAdd(uuid);

      this.$refs.addWheel.modal = true;
    },
    async getWheelsToAdd(uuid) {
      await this.fetchWheels();
      await this.fetchClientWheels({ uuid });

      const gamesIds = this.getWheels.map((game) => game.uuid);
      const operatorGamesIds = this.getClientWheels.map((wheel) => wheel.uuid);

      const difference = gamesIds.filter(
        (x) => operatorGamesIds.indexOf(x) === -1
      );
      const gamesToAdd = this.getWheels.filter((game) =>
        difference.includes(game.uuid)
      );

      return gamesToAdd;
    },
    async addExternalGame(model) {
      await this.fetchGames({
        path: 'external-games',
        options: {},
        type: EXTERNAL_GAMES,
      });

      await this.fetchClientExternalGames({ id: model._id });

      const gamesIds = this.getGames.map((game) => game._id);
      const operatorGamesIds = this.getClientExternalGames
        .filter(({ externalGame }) => externalGame)
        .map(({ externalGame }) => externalGame._id);

      const difference = gamesIds.filter(
        (x) => operatorGamesIds.indexOf(x) === -1
      );
      const roulettesToAdd = this.getGames.filter((roulette) =>
        difference.includes(roulette._id)
      );

      this.path = 'clients';
      this.endpoint = 'add-external-game';
      this.clientGames = roulettesToAdd;
      this.clientData = model;

      this.$refs.addGame.modal = true;
    },
    async remove(client) {
      const deleteClient = await this.handleDeleteSwal(
        'Está seguro que desea eliminar este cliente'
      );

      if (!deleteClient) return Swal.fire('Usuario no eliminado', '', 'info');

      try {
        await this.deleteClient({ client });

        if (!this.isSuccess)
          return this.handleAlertSwal('error', this.getMessage);

        this.handleAlertSwal('success', 'Cliente eliminado con exito');
        this.closeModa();
        this.getData();
        this.checkErrors();
      } catch (error) {
        console.log('ERROR DELETING CLIENT', error);
      }
    },
    openWinNotification(position = null, color, title, text, duration = 10000) {
      this.$vs.notification({
        color,
        position,
        title,
        text,
        duration,
        progress: 'auto',
      });
    },
    checkErrors() {
      if (this.getErrors.length) {
        this.getErrors.map(({ api, reason }) => {
          const message = `API: ${this.envs[api]} | REASON: ${reason}`;
          this.openWinNotification(null, 'warning', 'ERROR', message);
        });
      }
    },
    async blockClient(item) {
      const { _id: id } = item;
      if (!item.available) return this.disblockClient(id);
      const isConfirmed = await this.handleDeleteSwal(
        'Está seguro que desea bloquear este cliente?'
      );

      if (!isConfirmed) return Swal.fire('Operacion cancelada', '', 'info');

      try {
        await this.fetchBlockClient({ id });

        if (!this.isSuccess)
          return this.handleAlertSwal('error', 'Error bloqueando al cliente');

        this.handleAlertSwal('success', 'Cliente bloqueado con exito');
        this.getData();
      } catch (error) {
        console.log('ERROR BLOCK CLIENT', error);
      }
    },
    async disblockClient(id) {
      const isConfirmed = await this.handleDeleteSwal(
        'Está seguro que desea desbloquear este cliente?'
      );

      if (!isConfirmed) return Swal.fire('Operacion cancelada', '', 'info');

      await this.fetchDisblockClient({ id });

      if (!this.isSuccess)
        return this.handleAlertSwal('error', 'Error desbloqueando al cliente');

      this.handleAlertSwal('success', 'Cliente desbloqueado con exito');
      this.getData();
    },
    async addCurrency(item) {
      this.toggleLoader();

      const clientCurrencies = await this.fetchClientCurrencies({
        path: 'clients',
        id: item._id,
      });

      await this.fetchCurrencies({
        role: this.user.role.name,
        user: this.user,
      });
      this.clientData = { ...item, currencies: clientCurrencies };
      this.currencies = this.getCurrencies;

      this.toggleLoader();
      this.$refs.addCurrency.modal = true;
    },
    async handleError(e) {
      const image = await import('@/assets/images/users/pngegg.png');
      e.target.src = image.default;
    },
    closeModa() {
      this.$refs.pageForm.modal = false;
      this.typeform = {
        name: '',
        logo: '',
        endpointWin: '',
        endpointRollback: '',
        endpointBet: '',
        endpointAuth: '',
      };
      this.getData();
    },
    openUrls(item) {
      this.urlCliente = item;
      this.$refs.viewUrls.modal = true;
    },
    edit(item) {
      this.typeform = {};
      // eslint-disable-next-line no-unused-vars
      const { roulettes, ...restItem } = item;

      this.typeform = restItem;

      this.$refs.pageForm.modal = true;
    },
    ImportRoulette(item) {
      this.clientSave = item;
      const client = item;
      this.clientsAgaint = this.tableData;

      this.clientsAgaint = this.clientsAgaint.filter(function (item) {
        return item._id !== client._id;
      });

      this.$refs.ImportRoulette.modal = true;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    handleOpen(item) {
      this.clientData = item;
      this.isAdding = false;
      this.$refs.GameModalMenu.modal = true;
    },
    getUserRole() {
      this.userRole = this.$store.getters['user/getRole'];
    },
    async handleDeleteSwal(title) {
      const { isConfirmed } = await Swal.fire({
        title,
        showDenyButton: true,
      });

      if (!isConfirmed) return false;

      return true;
    },
    handleAlertSwal(icon, title) {
      Swal.fire({
        position: 'center',
        icon,
        title,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    // ACTIONS
    ...mapActions({
      fetchClientCurrencies: 'clients/fetchClientCurrencies',
      fetchClientRoulettes: 'clients/fetchClientRoulettes',
      fetchClientsRoulettes: 'clients/fetchClientsRoulettes',
      fetchCurrencies: 'currencies/fetchCurrencies',
      filterRoulette: 'clients/filterRoulette',
      fetchClients: 'clients/fetchClients',
      deleteClient: 'clients/deleteClient',
      fetchBlockClient: 'clients/blockClient',
      fetchDisblockClient: 'clients/disblockClient',
      fetchGames: 'games/findGames',
      fetchClientExternalGames: 'clients/fetchExternalGames',
      fetchClientDragonTigers: 'clients/fetchDragonTiger',
      fetchDragonTigers: 'dragonTiger/fetchGames',
      fetchWheels: 'wheel/fetchWheels',
      fetchClientWheels: 'clients/fetchWheels',
    }),
    ...mapMutations({
      toggleLoader: 'user/toggleOverlayLoader',
    }),
  },
  middleware: 'authentication',
};
</script>

<style scoped>
.action-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100px;
}

.logo-img {
  width: 80px;
  object-fit: contain;
}
</style>
