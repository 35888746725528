<template>
  <b-modal id="bv-modal-example" centered v-model="modal" size="lg" hide-footer>
    <template #modal-title>{{ $t('clients.URLs') }}: {{ item.name }} </template>
    <div>
      <div class="text-muted">
        <div class="table-responsive mb-0">
          <div>
            <p class="mb-1">{{ $t('clients.form.endpointAuth') }}:</p>
            <h5 class="font-size-16">{{ item.endpointAuth }}</h5>
          </div>
          <div>
            <p class="mt-4 mb-1">{{ $t('clients.form.endpointBet') }}:</p>
            <h5 class="font-size-16">{{ item.endpointBet }}</h5>
          </div>
          <div>
            <p class="mt-4 mb-1">{{ $t('clients.form.endpointWin') }}:</p>
            <h5 class="font-size-16">{{ item.endpointWin }}</h5>
          </div>
          <div class="mt-4 mb-5">
            <p class="mb-1">{{ $t('clients.form.endpointRollback') }}:</p>
            <h5 class="font-size-16">{{ item.endpointRollback }}</h5>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      modal: false,
    };
  },
  methods: {
    closeModa() {
      this.modal = false;
    },
  },
};
</script>
