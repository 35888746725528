import i18n from '../../i18n';

const active = true,
  homeField = { text: 'Sprint Gaming' },
  transactionsFields = [homeField, { text: i18n.t('sidebar.reports.text') }],
  manualPaymentFields = [
    homeField,
    { text: i18n.t('sidebar.manualPayment.text') },
  ];

export const breadCrumbData = {
  home: [
    homeField,
    {
      text: i18n.t('sidebar.home'),
      active,
    },
  ],
  clients: [
    homeField,
    {
      text: i18n.t('sidebar.clients'),
      active,
    },
  ],
  operators: [
    homeField,
    {
      text: i18n.t('sidebar.operators'),
      active,
    },
  ],
  players: [
    homeField,
    {
      text: i18n.t('sidebar.players'),
      active,
    },
  ],
  roulettes: [
    homeField,
    {
      text: i18n.t('sidebar.games.subItem.roulettes'),
      active,
    },
  ],
  tigerDragon: [
    homeField,
    {
      text: i18n.t('sidebar.games.subItem.tigerDragon'),
      active,
    },
  ],
  tigerDragonFisic: [
    homeField,
    {
      text: 'Tigre Dragón - Mesa Física',
      active,
    },
  ],
  sprintWheels: [
    homeField,
    {
      text: 'Rueda',
      active,
    },
  ],
  blackJack: [
    homeField,
    {
      text: 'Black Jack',
      active,
    },
  ],
  transactions: [
    ...transactionsFields,
    {
      text: 'Jugadores en linea',
      active,
    },
  ],
  betsAndViews: [
    ...transactionsFields,
    {
      text: 'APUESTAS Y VISITAS',
      active,
    },
  ],
  userHistory: [
    ...transactionsFields,
    {
      text: i18n.t('sidebar.reports.subItems.historial'),
      active,
    },
  ],
  providerLogs: [
    ...transactionsFields,
    {
      text: i18n.t('sidebar.reports.subItems.providerResults'),
      active,
    },
  ],
  reportPlayers: [
    ...transactionsFields,
    {
      text: i18n.t('sidebar.reports.subItems.players'),
      active,
    },
  ],
  reportPlayersDetails: [
    ...transactionsFields,
    {
      text: 'Jugadores',
      to: {
        name: 'reports-players',
      },
    },
    {
      text: 'Detalles de los jugadores',
      active,
    },
  ],
  ggr: [
    ...transactionsFields,
    {
      text: i18n.t('sidebar.reports.subItems.ggr'),
      active,
    },
  ],
  ggrByCasino: [
    ...transactionsFields,
    {
      text: i18n.t('sidebar.reports.subItems.ggrByCasino'),
      active,
    },
  ],
  ggrByWl: [
    ...transactionsFields,
    {
      text: 'GGR BY WL',
      active,
    },
  ],
  rounds: [
    ...transactionsFields,
    {
      text: i18n.t('sidebar.reports.subItems.rounds'),
      active,
    },
  ],
  fluctuation: [
    ...transactionsFields,
    {
      text: i18n.t('sidebar.reports.subItems.currencyFluctuation'),
      active,
    },
  ],
  individualPayment: [
    ...manualPaymentFields,
    {
      text: i18n.t('sidebar.manualPayment.subItem.individual'),
      active,
    },
  ],
  generalPayment: [
    ...manualPaymentFields,
    {
      text: i18n.t('sidebar.manualPayment.subItem.general'),
      active,
    },
  ],
  incidents: [
    homeField,
    {
      text: i18n.t('sidebar.incidents'),
      active,
    },
  ],
  userHelp: [
    homeField,
    {
      text: i18n.t('sidebar.help'),
      active,
    },
  ],
};
